import React from "react"
import BookingContext from "../context/BookingContext"

const CtaBtn = () => (
  <BookingContext.Consumer>
    {booking => (
      <button onClick={() => booking.toggleShow()} className="btn cta">
        Réserver
      </button>
    )}
  </BookingContext.Consumer>
)

export default CtaBtn

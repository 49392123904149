import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Image from "../components/tacticalImg"
import Cta from "../components/cta"

const Autres = () => (
  <Layout>
    <SEO title="Services" />
    <Hero title="Services" hero="services.jpg" />
    <div className="container highlow">
      <div
        className="grid"
        style={{ gridRowGap: "var(--medium)", marginBottom: "var(--medium)" }}
      >
        <div className="col-md-6 ">
          <div className="make-room-medium">
            <Image src="soins-visage-femme.jpg" alt="Soins du Visage" />
          </div>
        </div>
        <div
          className="col-md-6 vertical-center"
          style={{ padding: "0 var(--large)" }}
        >
          <h3>Soins du Visage</h3>
          <p>
            <ul className="services">
              <li>Nettoyage de peau</li>
              <li>Nettoyage profond + soin hydratant</li>
              <li>Massage facial</li>
              <li>Drainage Lymphatique facial Près Chirurgicale</li>
              <li>Soin hydratant</li>
              <li>Soin oxygénant et revitalisant</li>
              <li>soin peau grasse et acnéique</li>
              <li>Soin anti rougeurs</li>
              <li>Soin anti-âge coup d’éclat hydratant</li>
              <li>Soin anti-âge confort peau sensible et sèche</li>
              <li>Soin tenseur raffermissant</li>
            </ul>
          </p>
          <div style={{ margin: "var(--small) 0 0" }}>
            <Link to="/soins-visage/" className="btn soft-cta">
              En Savoir
            </Link>
          </div>
        </div>
      </div>
      <div className="grid" style={{ gridRowGap: "var(--medium)" }}>
        <div
          className="col-md-6 vertical-center"
          style={{ padding: "0 var(--large)" }}
        >
          <h3>Soins Corporels</h3>
          <p>
            <ul className="services">
              <li>Massage Classique ( Suédois, Relaxant)</li>
              <li>Massage Sportif</li>
              <li>Massage femme enceinte</li>
              <li>Massage pour le bébé</li>
              <li>Massage pour les jeunes enfants et adolescents</li>
              <li>Drainage lymphatique</li>
              <li>Massage aux pierres chaudes</li>
              <li>Massage à la bougie</li>
              <li>Massage assis</li>
              <li>Massage amincissant</li>
              <li>Massages thérapeutiques</li>
              <li>Massage myofasciale</li>
              <li>Massage d'aromathérapie</li>
              <li>Réflexologie</li>
              <li>Gommage</li>
              <li>Massage pour couples</li>
            </ul>
          </p>
          <div style={{ margin: "var(--small) 0 0" }}>
            <Link to="/soins-corporels/" className="btn soft-cta">
              En Savoir
            </Link>
          </div>
        </div>
        <div className="col-md-6 vertical-center mobile-first">
          <div className="make-room-medium">
            <Image src="soins-corporels-femme.jpg" alt="Soins Corporels" />
          </div>
        </div>
        <div className="col-md-6">
          <div className="make-room-medium">
            <Image src="epilation.jpg" alt="Soins du Visage" />
          </div>
        </div>
        <div
          className="col-md-6 vertical-center"
          style={{ padding: "0 var(--large)" }}
        >
          <h3>Épilation</h3>
          <p>
            <ul className="services">
              <li>Épilation à la cire pour femme</li>
              <li>Épilation à la cire pour homme</li>
            </ul>
          </p>
          <p>
            <strong>Cire tiède naturelle</strong>
            <br />
            Toutes les épilations sont réalisées avec des cire tièdes
            professionnelles aux normes européennes, d’excellente qualité et
            sont adaptées aux peaux les plus fragiles et sensibles.
          </p>
          <p>
            <strong>Cire chaude naturelle</strong>
            <br />À usage unique, travaillée en couche fine avec une spatule.
          </p>
        </div>
      </div>
      <div className="side padding">
        <div style={{ margin: "var(--xl2) 0 var(--xl)" }}>
          <Image src="manipedicure.jpg" alt="Esthétique" />
        </div>
        <h3 style={{ textAlign: "center", marginTop: "var(--medium)" }}>
          Esthétique
        </h3>
        <p style={{ maxWidth: "75ch", margin: "0 auto", textAlign: "center" }}>
          J’ai sélectionné pour vous soins deux marques de cosmétique réputées
          pour leur qualité et leur efficacité. Les cosmétiques utilisés sont
          des produits adaptés à votre type de peau et ont été sélectionnés
          parmi une gamme de produits naturels bio, aux textures agréables,
          formulés sans parabens. C’est aussi l’assurance de prestations
          effectuées sans précipitation, dans le respect des protocoles de soin
          et d’hygiène. Un contact chaleureux et une relation de confiance
          seront rapidement établis. Les soins esthétiques, contribuent à garder
          une humeur positive et une bonne image de soi.
        </p>
        <div className="grid">
          <div className="col-md-6">
            <div className="make-room-large">
              <Image src="golden-caviar.png" alt="Golden Caviar" />
            </div>
            <p style={{ textAlign: "center" }}>
              BIODROGA fait appel aux plus grands biochimistes, dermatologues et
              bio-techniciens d’Europe. Ces experts garantissent la qualité
              supérieure des agents entrant dans la composition des produits
              Biodroga. Ce sont des substances naturelles et des mécanismes
              végétaux de protection en combinaison avec des actifs
              biotechnologiques, des produits résultant tous de cette union. Ils
              sont rigoureusement analyses, développés et produits à
              Baden-Baden, en Allemagne.
            </p>
          </div>
          <div className="col-md-6">
            <div className="make-room-large">
              <Image src="decleor.png" alt="Decléor" />
            </div>
            <p style={{ textAlign: "center" }}>
              DECLÉOR, est une marque professionnelle reconnue par les
              instituts. Leurs produits professionnels viennent renforcer
              l’efficacité des soins avec des formules uniques, une
              concentration renforcée d’actifs et des résultats visibles
              constatés. Experts en aromathérapie, ils utilisent l’énergie des
              huiles essentielles au service de la beauté depuis plus de 40 ans.
            </p>
          </div>
        </div>
      </div>
    </div>
    <Cta />
  </Layout>
)

export default Autres

import React from "react"
import styled from "styled-components"
import CtaBtn from "./ctabtn"

const CtaSection = styled.section`
  background: var(--soft);
  font-family: "Prata", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 700;
  padding: var(--xl) 0;
  font-size: 2.25rem;
  line-height: 3rem;
  text-align: center;
`

const CTA = () => {
  return (
    <CtaSection>
      <div className="container grid">
        <div className="col-md-1"></div>
        <div className="col-md-10">
          <p style={{ marginBottom: "var(--medium)" }}>
            Réserve votre massage en ligne!
          </p>
          <CtaBtn />
        </div>
        <div className="col-md-1"></div>
      </div>
    </CtaSection>
  )
}

export default CTA
